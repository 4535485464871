import React from 'react'
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

const ConditionalWrapper = ({ condition, wrapper, children }) => 
condition ? wrapper(children) : children;

const BookSection = (props) => {
  // swap for static image on preview
  const coverImageSelector =  props.coverImage
    ? <Img fixed={props.coverImage} alt={`${props.title} Book`} /> 
    : <img 
        src={props.previewImage} 
        style={{
          width: 200,
          height: 300,
        }}
        alt={`${props.title} Book`} />
  return(
    <ConditionalWrapper 
    condition={props.backgroundImage}
    wrapper={children => <BackgroundImage fluid={props.backgroundImage}>{children}</BackgroundImage>}>
      <div className={`container section`}>
        <div className={`row`}>
          <div className="col-md-4 offset-md-2 col-sm-6 text-center">
            {coverImageSelector}
          </div>
          <div className={`col-sm-4 col-sm-6 bookDetails`}>
            <div className="row">
              <div className="col-sm-12 container">
                <h2 style={{color : props.titleColor}}>{props.title}</h2>
                <h3 className="h5">{props.subtitle}</h3>
                <p>{props.excerpt}</p>
                <div className="centerMob">
                  <a className="btn btn-primary" href={props.link}>Buy now at Amazon</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`row bookDescription`}>
          <div className="col-sm-12 container">
            <div dangerouslySetInnerHTML={{ __html: props.body}} />
            {props.previewBody}
          </div>
        </div>
      </div>
    </ConditionalWrapper>
  )
}

export default BookSection
