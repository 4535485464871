import React from 'react'
import Img from "gatsby-image"
// import aboutStyles from './about.module.scss'

const About = (props) => {
  // swap for static image on preview
  const profilePicSelector =  props.profilePic
    ? <Img className='profilePic' fixed={props.profilePic} alt='Staurt Doughty Headshot' /> 
    : <img 
        src={props.previewProfilePic} 
        alt='Staurt Doughty Headshot'
        className='profilePic'
        style={{
          height: 200,
        }}
        />
  return(
    <div className={`container section`}>
      {profilePicSelector} 
      <h2>{props.title}</h2>
      <div dangerouslySetInnerHTML={{ __html: props.body}} />
      {props.previewBody}
    </div>
  )
}

export default About