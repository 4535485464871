import React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import Layout from './components/layout'
import BookSection from './components/bookSection'
import Head from './components/head'
import About from './components/about'
import DownloadSection from './components/downloadSection'

const IndexPage = (props) => {
  const data = useStaticQuery(graphql`
  query allBooks {
    allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "book"}}}, sort: {fields: frontmatter___datePublished, order: ASC}) {
      edges {
        node {
          html
          frontmatter {
            title
            color
            subtitle
            excerpt
            link
            datePublished
            frontCover {
              childImageSharp {
                fixed(height: 300){
                  ...GatsbyImageSharpFixed
                }
              }
            }
            backgroundImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    aboutPage: markdownRemark(frontmatter: {templateKey: {eq: "about-page"}}) {
      html
      frontmatter {
        title
        profilePic {
          childImageSharp {
            fixed(height: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
    downloadPage: markdownRemark(frontmatter: {templateKey: {eq: "free-download"}}) {
      html
      frontmatter {
        title
        backgroundImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        firstCover {
          childImageSharp {
            fixed(height: 300){
              ...GatsbyImageSharpFixed
            }
          }
        }
        secondCover {
          childImageSharp {
            fixed(height: 300){
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
  `)
  const bookData = data.allMarkdownRemark.edges
  
  let output = bookData.map(edge => {
    return(
      <BookSection 
        title={edge.node.frontmatter.title} 
        titleColor={edge.node.frontmatter.color}
        subtitle={edge.node.frontmatter.subtitle} 
        excerpt={edge.node.frontmatter.excerpt}
        link={edge.node.frontmatter.link}
        body={edge.node.html}
        coverImage={edge.node.frontmatter.frontCover.childImageSharp.fixed}
        backgroundImage={edge.node.frontmatter.backgroundImage
          ? edge.node.frontmatter.backgroundImage.childImageSharp.fluid
          : ''} 
      />
    )
  })

  return (
    <Layout>
      <Head title="Home"/>
      {output}
      <About 
        body={data.aboutPage.html} 
        title={data.aboutPage.frontmatter.title} 
        profilePic={data.aboutPage.frontmatter.profilePic
          ? data.aboutPage.frontmatter.profilePic.childImageSharp.fixed
        : ''}
      />
      <DownloadSection 
        backgroundImage={data.downloadPage.frontmatter.backgroundImage
          ? data.downloadPage.frontmatter.backgroundImage.childImageSharp.fluid
          : ''} 
        coverImage={data.downloadPage.frontmatter.firstCover.childImageSharp.fixed}
        secondImage={data.downloadPage.frontmatter.secondCover.childImageSharp.fixed}
        />
    </Layout>
  )
}

export default IndexPage